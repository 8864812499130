
import { useCallback, useEffect, useState } from "react";
import { fetchStateType } from "../../models/types";
import { getAdminDeviceById } from "../../utilities/axios/admin-api-calls/adminCalls";
import { DetailedDevice } from "../../models/detailedDevice.model";

export const useAdminDevice = (adminDeviceId: string | undefined) => {

  const [adminDevice, setAdminDevice] = useState<DetailedDevice | null>(null);
  const [fetchState, setFetchState] = useState<fetchStateType>("default");

  const fetchAdminDevice = useCallback(async () => {
    if (!adminDeviceId) return;    
    setFetchState("loading")
    let device = await getAdminDeviceById(adminDeviceId);
    setAdminDevice(device);
    if(device) setFetchState("fetched")
    else setFetchState("error")
  }, [adminDeviceId]);

  useEffect(() => {
    if (!adminDevice && adminDeviceId) fetchAdminDevice();
  }, [adminDevice, adminDeviceId, fetchAdminDevice]);

  return {adminDevice, fetchAdminDevice, fetchState, setFetchState};
};


export type useAdminDeviceType = ReturnType<typeof useAdminDevice>
