import { useCallback, useEffect, useMemo, useState } from "react";
import { PaginationQuery } from "../../utilities/api";
import { fetchStateType } from "../../models/types";
import { getUsers } from "../../utilities/axios/admin-api-calls/users-calls/usersCalls";
import { config } from "../../config";

export const SCREEN_USER_NAME_PREFIX = "screen";
export const useAddScreenUser = (getByGroup = false) => {
  const [screenUsersCountFetchState, setScreenUsersCountFetchState] =
    useState<fetchStateType>("default");

  const [totalScreenUsersGroup, setTotalScreenUsersGroup] = useState(-1);
  const [totalScreenUsersName, setTotalScreenUsersName] = useState(-1);

  const canStartCalc = useMemo(
    () => !(screenUsersCountFetchState !== "default"),
    [screenUsersCountFetchState]
  );

  const internalGetScreenUsers = useCallback(
    async (page: number = 1, pageSize: number = 100) => {
      let screenUsersResp = await getUsers(
        new PaginationQuery(page, pageSize),
        SCREEN_USER_NAME_PREFIX,
        [config.screenUsers.staticScreenGroupId]
      );

      return screenUsersResp;
    },
    []
  );

  const calcTotalScreenUsersInGroup = useCallback(async () => {
    if (!canStartCalc) return -1;

    setScreenUsersCountFetchState("loading");

    let users = await internalGetScreenUsers();

    if (!users) {
      setScreenUsersCountFetchState("error");
      return -1;
    }

    // there are at least
    let screenUsersMin = users?.items.length;

    // if the initial pagination call is not full, we know the total screen use count
    if (screenUsersMin < users?.pageSize || screenUsersMin === 0)
      return screenUsersMin;

    users = await getUsers(new PaginationQuery(1, 1));

    if (!users) {
      setScreenUsersCountFetchState("error");
      return -1;
    }

    // there are max this many in the group
    let screenUsersMax = users.totalItems;

    const pageSize = Math.min(100, screenUsersMax);

    users = await internalGetScreenUsers(screenUsersMax - pageSize, pageSize);

    if (!users) {
      setScreenUsersCountFetchState("error");
      return -1;
    }

    if (users.items.length > 0)
      return screenUsersMax - pageSize + users.items.length;

    screenUsersMax = screenUsersMax - pageSize;

    // do binary search to find the correct count
    let isDone = false;
    let iterLimit = Math.log2(screenUsersMax - screenUsersMin) + 1;

    let count = 0;
    while (!isDone) {
      let midpoint =
        Math.floor((screenUsersMax - screenUsersMin) / 2) + screenUsersMin;
      let pagenum = Math.floor(midpoint / pageSize);

      users = await internalGetScreenUsers(pagenum, pageSize);

      if (!users) {
        setScreenUsersCountFetchState("error");
        return -1;
      }

      if (users.items.length > 0)
        return screenUsersMax - pageSize + users.items.length;

      if (users.items.length === pageSize) {
        screenUsersMin = midpoint + 1;
      } else {
        screenUsersMax = midpoint - 1;
      }

      isDone = screenUsersMin === screenUsersMax || count > iterLimit;
      count += 1;
    }

    return screenUsersMax;
  }, [internalGetScreenUsers, canStartCalc]);

  const calcTotalScreenUsersWithName = useCallback(async () => {
    if (screenUsersCountFetchState !== "default") return;
    setScreenUsersCountFetchState("loading");
    let screenUsersResp = await getUsers(
      new PaginationQuery(),
      SCREEN_USER_NAME_PREFIX
    );

    if (!screenUsersResp) {
      setScreenUsersCountFetchState("error");
      return;
    }

    setScreenUsersCountFetchState("fetched");

    setTotalScreenUsersName(screenUsersResp?.totalItems ?? -1);
  }, [screenUsersCountFetchState]);

  const getTotalScreenUsers = useCallback(async () => {
    if (canStartCalc) {
      let resp = await calcTotalScreenUsersInGroup();

      if (resp >= 0) {
        setScreenUsersCountFetchState("fetched");
        setTotalScreenUsersGroup(resp);
      }
    }
  }, [calcTotalScreenUsersInGroup, canStartCalc]);

  useEffect(() => {
    if (getByGroup) getTotalScreenUsers();
    else calcTotalScreenUsersWithName();
  }, [getTotalScreenUsers, calcTotalScreenUsersWithName, getByGroup]);


  const userScreenName = useMemo(() => {
    let totalScreenUsers = getByGroup
      ? totalScreenUsersGroup
      : totalScreenUsersName;

    if (screenUsersCountFetchState !== "fetched" || totalScreenUsers === -1)
      return null;

    return `${SCREEN_USER_NAME_PREFIX}${totalScreenUsers + 1}`;
  }, [
    totalScreenUsersGroup,
    totalScreenUsersName,
    getByGroup,
    screenUsersCountFetchState,
  ]);


  const userEmail = useMemo(() => {
    if (userScreenName === null) return null    
    return `${userScreenName}@resani.com`;
  }, [
    userScreenName,
  ]);



  
  return {
    totalScreenUsers: getByGroup ? totalScreenUsersGroup : totalScreenUsersName,
    screenUsersCountFetchState,
    userScreenName,
    userEmail,
  };
};
