import { config, signalRFunctions } from "../../config";
import {
  EventType,
  ReceivedEvent,
  BaseMessage,
  BroadEventType,
  UnknownEvent,
  UNKNOWN_EVENT,
  DeviceEvent,
  BluetoothEvent,
  BluetoothEventType,
  QueryableEventTypeNumbers,
  EventTypeEnum,
  TransactionEventType,
  TransactionEvent,
  SummaryEventType,
  SummaryEvent,
} from "../../models/deviceEvents.model";
import { getEventColor } from "./eventColors";

export const mapEventTypeNumbersV1: (eventType: BroadEventType) => number = (
  eventType: BroadEventType
) => {
  let eventSpec = config.eventLogSpecs.events.find(
    (eventSpec) => eventSpec.name === eventType
  );

  if (!eventSpec) throw new Error(`unable to map ${eventType} to v1 event`);
  return eventSpec.enumValue;
};

export const mapEventTypeNumbers: (eventType: BroadEventType) => number = (
  eventType: BroadEventType
) => {
  let eventSpec = config.eventLogSpecs.events.find(
    (eventSpec) => eventSpec.name === eventType
  );

  if (!eventSpec) throw new Error(`unable to map ${eventType} to  event`);
  return eventSpec.enumValue;
};

export const isRecentEvent = (receivedEvent: ReceivedEvent) => {
  let eventAge = milliSecondsSinceTimeStamp(receivedEvent.event.timestamp);
  return eventAge < 1000 * 25;
};

export const milliSecondsSinceTimeStamp = (timeStamp: string) => {
  var difference = new Date().getTime() - new Date(timeStamp).getTime();
  return difference;
};

export const getFilteredEventTypes = (filterDictionary: {
  [key: string]: boolean;
}) => {
  let filterForArray: BroadEventType[] = [];

  var filteredV1List = Object.entries(filterDictionary)
    .filter((eventType) => eventType[1])
    .filter((eventType) => eventType[0])
    .map((eventType) => eventType[0]);

  var filteredList = Object.entries(filterDictionary)
    .filter((eventType) => eventType[1])
    .filter((eventType) => eventType[0])
    .map((eventType) => eventType[0]);

  filterForArray = filterForArray.concat(filteredV1List);
  filterForArray = filterForArray.concat(filteredList);

  if (filterDictionary[BluetoothEventType])
    filterForArray.push(BluetoothEventType);

  return filterForArray;
};

export const filterEvents = (
  events: ReceivedEvent[],
  filter: BroadEventType[]
) => {
  return events.filter((event) => filter.includes(event.eventType));
};

export const filterIsOn = (filterDictionary: { [key: string]: boolean }) => {
  let filtered = getFilteredEventTypes(filterDictionary);
  return filtered.length > 0;
};

export const parseSignalREvent: (
  callbackValue: string,
  message: any
) => ReceivedEvent = (callbackValue: string, message: any) => {
  return parseEvent(callbackValue, message);
};

function parseEvent(callbackValue: string, message: any): ReceivedEvent {
  const { name, event } = mapToEventInfo(message);

  let eventType = mapEventType(callbackValue, event);

  return {
    event: event,
    eventType: eventType,
    source: "broadcaster",
    color: getEventColor(eventType, event),
    name: name,
  };
}

interface EventInfo {
  name: string;
  event: BaseMessage;
}

function mapToEventInfo(message: any): EventInfo {
  let event = message;
  let name = UNKNOWN_EVENT.toString();
  if (objIsDeviceEvent(message)) {
    event = message as DeviceEvent;
    name = event.event;
  } else if (objIsBluetoothEvent(message)) {
    name = BluetoothEventType;
    event = message as BluetoothEvent;
  } else if (objIsTransactionEvent(message)) {
    name = TransactionEventType;
    event = message as TransactionEvent;
  } else if (objIsSummaryEvent(message)) {
    name = SummaryEventType;
    event = message as SummaryEvent;
  } else {
    name = UNKNOWN_EVENT;
    event = message as BaseMessage;
  }

  return {
    name,
    event,
  };
}

function mapEventType(
  callbackValue: string,
  event: BaseMessage
): BroadEventType {
  switch (callbackValue) {
    case signalRFunctions.device.ReceiveDeviceEvent:
      return mapEventToEventType(event);
    case signalRFunctions.device.ReceiveTransactionEvent:
      return TransactionEventType;
    case signalRFunctions.device.ReceiveBluetoothEvent:
      return EventTypeEnum.BluetoothEvent;
    case signalRFunctions.device.ReceiveConfigEvent:
      return EventTypeEnum.State;
    default:
      return UNKNOWN_EVENT;
  }
}

function mapEventToEventType(event: BaseMessage): EventType | UnknownEvent {
  if (objIsTransactionEvent(event)) return TransactionEventType;

  let ev = event as DeviceEvent;
  return ev.event as EventType;
}

export function asQueryableEventTypeList(arr: EventType[] | undefined) {
  if (!arr) return undefined;
  let eventTypeNumbers: number[] = arr
    .filter((eventType) => eventType in QueryableEventTypeNumbers)
    .map(mapEventTypeNumbers);
  return eventTypeNumbers;
}

export function isEventUnique(
  thisEvent: ReceivedEvent,
  index: number,
  self: ReceivedEvent[]
): boolean {
  return (
    self.findIndex(
      (otherEvent) =>
        otherEvent.name === thisEvent.name &&
        otherEvent.event.timestamp === thisEvent.event.timestamp &&
        otherEvent.event.deviceId === thisEvent.event.deviceId
    ) === index
  );
}

export function objIsDeviceEvent(obj: any) {
  return "event" in obj;
}

export function objIsBluetoothEvent(obj: any) {
  return "address" in obj && "rssi" in obj;
}

export function objIsTransactionEvent(obj: any) {
  return "userGroupId" in obj && "shiftTypeId" in obj && "sectionId" in obj;
}
export function objIsSummaryEvent(obj: any) {
  return "revision" in obj && "counterNumber" in obj && "counterValue" in obj;
}
