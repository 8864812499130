import { Link as RouterLink, LinkProps } from "react-router-dom";
import { Link, SxProps, Theme } from "@mui/material";

import { forwardRef, useMemo } from "react";
import { useTenant } from "../../hooks/tenant";

type props = {
    to: string,
    tenantSubLink?: boolean,
    children: string |  React.ReactNode,
    sx?: SxProps<Theme> | undefined
  }

const Linkage = ({to, tenantSubLink = false, children, sx}: props) => {
  const tenant = useTenant()
  const CustomLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'>>(function Linker(
        linkProps,
        ref,
      ) {
        if(!tenantSubLink || !tenant)
        return <RouterLink ref={ref}  to={to} {...linkProps} />;


        return <RouterLink ref={ref}  to={`/${tenant.identifier}${to}`} {...linkProps} />;      }),
    [to, tenantSubLink, tenant],
  );

  return <Link component={CustomLink} state={"state"}  sx={sx}>
  {children}
</Link>
}

export default Linkage