import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeviceSession, UserSession } from "../../models/maintenance.model";
import {
  fetchMaintanceActive,
  fetchServiceTenant,
  setMaintenanceModeClicked,
} from "../../store/actions/maintenanceActions";
import { RootState } from "../../store/reducers";
import { getAdminDeviceById } from "../../utilities/axios/admin-api-calls/adminCalls";
import { roundUpToNextWholeMinute } from "../../utilities/datetime";
import { useServiceTenant, useTenant } from "../tenant";
import { dateToFromNowDaily } from "../../utilities/helpers";
import { fetchStateType } from "../../models/types";
import { getMaintenanceSessions } from "../../utilities/axios/maintenance-api-calls";
import { Dictionary, groupBy, keys } from "lodash";
import { PaginationQuery } from "../../utilities/api";
import { pathToTenantDevice } from "../../utilities/paths";

export const useMaintenance = () => {
  const tenant = useTenant();
  const dispatch = useDispatch();
  const maintenanceState = useSelector(
    (state: RootState) => state.maintenanceReducer
  );
  const maintenanceActiveOnTenant = useMemo(() => {
    if (
      tenant?.identifier ===
        maintenanceState.serviceTenant.tenant?.identifier &&
      maintenanceState.serviceTenant
    )
      return false;

    return (
      maintenanceState.maintenanceModeActive &&
      tenant?.identifier === maintenanceState.tenant?.identifier
    );
  }, [
    tenant,
    maintenanceState.tenant?.identifier,
    maintenanceState.serviceTenant,
    maintenanceState.maintenanceModeActive,
  ]);

  const currentlyAtServiceTenant = useMemo(
    () =>
      tenant &&
      maintenanceState.serviceTenant.tenant?.identifier === tenant?.identifier,
    [maintenanceState, tenant]
  );

  const getMaintenanceState = useCallback(async () => {
    if (!tenant) return;

    dispatch(fetchMaintanceActive());
  }, [tenant, dispatch]);

  useEffect(() => {
    if (maintenanceState.serviceTenant.fetchState === "default")
      dispatch(fetchServiceTenant());
  }, [maintenanceState.serviceTenant, maintenanceState.fetchState, dispatch]);

  useEffect(() => {
    if (maintenanceState.fetchState === "default") getMaintenanceState();
  }, [tenant, getMaintenanceState, maintenanceState.fetchState]);

  
  useEffect(() => {
    if (
      tenant &&
      tenant?.identifier !== maintenanceState.tenant?.identifier &&
      !currentlyAtServiceTenant
    )
      getMaintenanceState();
  }, [tenant, maintenanceState, currentlyAtServiceTenant, getMaintenanceState]);


  const handleMaintenanceButtonClicked = () => {
    if (!tenant) return;

    dispatch(setMaintenanceModeClicked(tenant));
  };
  return {
    ...maintenanceState,
    maintenanceActiveOnTenant,
    currentlyAtServiceTenant,
    handleMaintenanceButtonClicked,
    dispatch,
  };
};

export const useMaintenanceSessionRedirectCallback = (
  deviceSession: DeviceSession | null,
  deviceId: string
) => {
  const navigate = useNavigate();

  const serviceTimeCronJobEnd = useMemo(
    () =>
      deviceSession
        ? roundUpToNextWholeMinute(deviceSession?.serviceTimeEnd)
        : null,
    [deviceSession]
  );

  const redirectCallback = useCallback(async () => {
    if (!deviceSession || deviceSession.serialNumber !== deviceId) return;

    let adminDevicefetched = await getAdminDeviceById(
      deviceSession.serialNumber
    );

    if (!adminDevicefetched?.tenant?.identifier) {
      console.error("Fetched admin device but no tenant identitfier was given");
      return;
    }

    navigate(
      pathToTenantDevice(adminDevicefetched?.tenant?.identifier ?? "", deviceSession.serialNumber)
    );
  }, [deviceSession, navigate, deviceId]);

  useEffect(() => {
    let msToEnd = moment(serviceTimeCronJobEnd).diff(moment());

    //add 5 seconds for the job to finish
    msToEnd += 5 * 1000;

    const timeoutId = setTimeout(redirectCallback, msToEnd);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [deviceSession, serviceTimeCronJobEnd, redirectCallback]);

  return { serviceTimeCronJobEnd, redirectCallback };
};

type useMaintenanceHistoryType = () => {
  sortedDictKeys: string[];
  maintenanceUserSessions: Dictionary<UserSessionHistoried[]>;
  canLoadMore: boolean;
  shouldDisplayDate: (
    sessions: UserSessionHistoried[],
    session: UserSessionHistoried,
    index: number
  ) => boolean;
  sessionsAreOnSameDay: (
    session: UserSessionHistoried,
    session2: UserSessionHistoried
  ) => boolean;
  handleLoadMore: () => void;
  fetchState: fetchStateType;
};

export const useMaintenanceHistory: useMaintenanceHistoryType = () => {
  const tenant = useTenant();
  const serviceTenant = useServiceTenant();

  const [fetchState, setFetchState] = useState<fetchStateType>("default");
  const [maintenanceUserSessions, setMaintenanceUserSessions] = useState<
    Dictionary<UserSessionHistoried[]>
  >({});
  const [rawSessions, setRawSessions] = useState<UserSessionHistoried[]>([]);
  const [sortedDictKeys, setSortedDictKeys] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [dataReady, setDataReady] = useState(false);

  const canLoadMore = useMemo(
    () => currentPage < totalPages && fetchState === "fetched" && dataReady,
    [fetchState, totalPages, currentPage, dataReady]
  );

  const getUserSessions = useCallback(async () => {
    if (tenant === serviceTenant.tenant || fetchState !== "default" || !tenant)
      return;

    setFetchState("loading");
    setDataReady(false);

    let sessions = await getMaintenanceSessions(
      new PaginationQuery(currentPage, USER_SESSION_PAGE_SIZE),
      undefined,
      [tenant?.identifier]
    );
    if (!sessions) {
      setFetchState("error");
      return;
    }

    setTotalPages(sessions.totalPages);

    let sessionItems = sessions.items.map((session, index) => {
      return {
        ...session,
        dayCreated: dateToFromNowDaily(new Date(session["created"])),
        index: index + rawSessions.length,
      } as UserSessionHistoried;
    });

    setRawSessions([...rawSessions, ...sessionItems]);
    setFetchState("fetched");
  }, [fetchState, tenant, rawSessions, serviceTenant, currentPage]);

  useEffect(() => {
    if (fetchState === "fetched") {
      rawSessions.sort(function (a, b) {
        let a_ = new Date(a["created"]);
        let b_ = new Date(b["created"]);
        return b_.valueOf() - a_.valueOf();
      });

      let groupedSessions = groupBy(rawSessions, "dayCreated");
      let groupKeys = keys(groupedSessions);

      groupKeys.sort(
        (a, b) =>
          new Date(groupedSessions[b][0].created).getTime() -
          new Date(groupedSessions[a][0].created).getTime()
      );

      setMaintenanceUserSessions(groupedSessions);
      setSortedDictKeys(groupKeys);
      setDataReady(true);
    }
  }, [fetchState, rawSessions]);

  useEffect(() => {
    if (fetchState === "default") getUserSessions();
  }, [fetchState, getUserSessions]);

  function shouldDisplayDate(
    sessions: UserSessionHistoried[],
    session: UserSessionHistoried,
    index: number
  ) {
    var sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    if (new Date(session.created) > sevenDaysAgo) return false;

    if (index > 0) {
      if (
        new Date(sessions[index - 1].created).getDate() ===
        new Date(session.created).getDate()
      )
        return false;
    }
    return true;
  }

  function sessionsAreOnSameDay(
    session: UserSessionHistoried,
    session2: UserSessionHistoried
  ) {
    return (
      new Date(session.created).getDate() ===
      new Date(session2.created).getDate()
    );
  }

  const handleLoadMore = useCallback(() => {
    if (!canLoadMore) return;

    setCurrentPage(1 + currentPage);
    setFetchState("default");
  }, [currentPage, canLoadMore]);

  return {
    sortedDictKeys,
    maintenanceUserSessions,
    canLoadMore,
    fetchState,
    shouldDisplayDate,
    sessionsAreOnSameDay,
    handleLoadMore,
  };
};

interface UserSessionHistoried extends UserSessionIndexed {
  dayCreated: string;
}

interface UserSessionIndexed extends UserSession {
  index: number;
}
const USER_SESSION_PAGE_SIZE = 10;
