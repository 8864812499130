import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BluetoothEventType,
  DeviceEventCollection,
  ReceivedEvent,
  TransactionEventType,
} from "../models/deviceEvents.model";
import { RootState } from "../store/reducers";
import {
  PagedResponse,
  PaginationQuery,
} from "../utilities/api";
import { getDeviceEventsByDevice } from "../utilities/axios/admin-api-calls/tenant-calls";
import { useIsAuthenticated } from "./authentication-hooks/authenticationHooks";
import { useFilter } from "./deviceEvent-hooks/filterHooks";
import {
  asQueryableEventTypeList,
} from "../utilities/events/eventUtils";
import { fetchStateType } from "../models/types";
import { SortedApiEvents, mergeAndSortDeviceEventCollection } from "../utilities/events/api";

export const useSortedLatestEventsByDevice = (
  deviceId: string
): SortedApiEvents => {
  const [processedData, setProcessedData] = useState<ReceivedEvent[]>([]);
  const tenant = useSelector((state: RootState) => state.tenantReducer.tenant);
  const { filter, maxEventsInFeed, filterOn } = useFilter();
  const [sorting, setSorting] = useState(false);
  const [fetchState, setFetchState] = useState<fetchStateType>("default");
  const [data, setData] = useState<PagedResponse<DeviceEventCollection> | null>(
    null
  );

  const { isAuthenticated } = useIsAuthenticated();

  const processEventCollection = useCallback(
    (collection: PagedResponse<DeviceEventCollection>) => {
      if (sorting) return;
      let mergedAndSorted = mergeAndSortDeviceEventCollection(
        collection.items[0]
      );
      
      setProcessedData(mergedAndSorted);
      setSorting(false);
    },
    [sorting]
  );

  const callApi = useCallback(async () => {
    if (!tenant || !isAuthenticated || fetchState !== "default") return;
    setFetchState("loading");

    let collection: PagedResponse<DeviceEventCollection> | null;

    if (!filterOn) {
      collection = await getDeviceEventsByDevice(
        tenant.identifier,
        deviceId ?? "",
        new PaginationQuery(0, maxEventsInFeed)
      );
    } else {
      let eventTypeNumbers =  asQueryableEventTypeList(filter);

      collection = await getDeviceEventsByDevice(
        tenant.identifier,
        deviceId ?? "",
        new PaginationQuery(0, maxEventsInFeed),
        eventTypeNumbers,
        filter.some(f => f === BluetoothEventType),
        filter.some(f => f === TransactionEventType),
      );
    }
    
    if (collection) {
      setFetchState("fetched");
      setData(collection);
      processEventCollection(collection);
    } else {
      setFetchState("error");
    }
  }, [
    deviceId,
    isAuthenticated,
    tenant,
    filter,
    maxEventsInFeed,
    filterOn,
    fetchState,
    processEventCollection,
  ]);

  useEffect(() => {
    setSorting(false);
    setFetchState("default");
    setData(null);
    setProcessedData([]);
  }, [tenant, filter, filterOn, maxEventsInFeed]);

  useEffect(() => {
    if (deviceId && fetchState === "default" && !data && tenant) {
      callApi();
    }
  }, [deviceId, data, fetchState, tenant, callApi]);

  return {
    events: processedData,
    loading: fetchState === "loading",
  };
};
